import api from '@/vantool/utils/axios'

export default {
  async getByCustomer(customerId) {
    try {
      const response = await api.get(`/users/${customerId}/bikes`)
      return response.data
    } catch (error) {
      error.message = `Failed loading bikes for customer with id ${customerId}: ${error.message}`
      throw error
    }
  },

  getByAuthenticatedUser() {
    return api.get('/public/v1/me/bikes').then(({ data }) => {
      this.state.bikes = data
      return data
    })
  },

  getByQRCode(code) {
    return api
      .get(`/bikes/qrcode/${code}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 404) {
          error.message = `A bike with the QR code ${code} does not exist.`
        }
        error.message = `Failed loading bike by QR code: ${error.message}`
        throw error
      })
  },

  getById(id) {
    return api.get(`/bikes/${id}`).then((response) => response.data)
  },

  async update(bikeId, params) {
    let response
    try {
      response = await api.patch(`/bikes/${bikeId}/`, params)
    } catch (error) {
      error.message = `Failed updating bike: ${error.message}`
      throw error
    }

    console.log('BIKE: updated with success', response.data)
    return response.data
  },

  async create(params) {
    let response
    try {
      response = await api.post('/bikes/', params)
    } catch (error) {
      error.message = `Failed creating bike: ${error.message}`
      throw error
    }

    console.log('BIKE: created with success', response.data)
    return response.data
  },

  async createOrUpdate(params) {
    if (!params.id) return await this.actions.bikes.create(params)

    return await this.actions.bikes.update(params.id, params)
  },

  createFleetBike(businessSlug, qrCode, serialNumber, makeAndModelId, isEbike, attributes = null) {
    const payload = {
      qr_code: qrCode,
      serial_number: serialNumber,
      make_and_model: makeAndModelId,
      is_ebike: isEbike
    }
    if (attributes) payload.attributes = attributes

    return api.post(`/vantool/businesses/${businessSlug}/bikes`, payload).then(({ data }) => data)
  },

  async searchBusinessBikes(businessId, searchTerm) {
    let response
    try {
      response = await api.get(`/my-business/bikes?businesses=${businessId}`)
    } catch (error) {
      error.message = `Failed to get bikes: ${error.message}`
      throw error
    }
    console.log(response)
    return response.data
      .filter(
        (bike) =>
          bike.qr_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          bike.serial_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          `${bike.make_and_model.make} ${bike.make_and_model.model}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          bike.custom_attributes.some(({ value }) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          )
      )
      .map((bike) => ({
        ...bike,
        custom_attributes: Object.fromEntries(
          bike.custom_attributes.map(({ name, value }) => [name, value])
        )
      }))
  },

  requestSwap(businessSlug, bikeId) {
    return api.post(`/businesses/${businessSlug}/bikes/${bikeId}/swap-requests`)
  }
}
