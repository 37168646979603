import { Cookies } from 'quasar'

import api from '@/vantool/utils/axios'

export default {
  // FIXME: horrible queries here that really don't scale
  get() {
    return api.get('/users/').then(({ data }) => {
      const keyedById = data.reduce((col, u) => {
        col[u.id] = u
        return col
      })
      this.state.users = keyedById
      return keyedById
    })
  },

  find(id) {
    return api.get(`/users/`, { params: { id } }).then(({ data }) => {
      data.forEach((u) => {
        this.state.users[u.id] = u
      })
      return this.state.users[id]
    })
  },

  getByEmail(email) {
    return api
      .get(`/users/email/${email}`)
      .then(({ data }) => {
        const user = data
        this.state.users[user.id] = user
        return user
      })
      .catch((error) => {
        if (error.response.status === 404) error.message = 'A user with this email does not exist.'
        else error.message = `Failed loading user by email: ${error.message}`

        throw error
      })
  },

  findAll(token) {
    return api.get('/users/', { params: { token } }).then(({ data }) => {
      if (data) {
        data.forEach((user) => {
          this.state.users[user.id] = user
        })
        return data
      } else {
        throw new Error("Can't find users with token " + token)
      }
    })
  },

  async update(id, params) {
    let response
    try {
      response = await api.patch(`/users/${id}/`, params)
    } catch (error) {
      error.message = `Failed updating user: ${error.message}`
      throw error
    }

    console.log('USER: updated with success', response.data)
    return response.data
  },

  async create(params) {
    let response
    try {
      response = await api.post('/users/', params)
    } catch (error) {
      error.message = `Failed creating user: ${error.message}`
      throw error
    }

    console.log('USER: created with success', response.data)
    return response.data
  },

  async createOrUpdate(params) {
    let customerProfile = null
    if (params.customerprofile) {
      customerProfile = params.customerprofile
      delete params.customerprofile
    }

    let user
    if (params.id) user = await this.actions.users.update(params.id, params)
    else user = await this.actions.users.create(params)

    if (customerProfile) await this.actions.customerprofiles.update(user.id, customerProfile)

    return user
  },

  validateEmailAddress(address) {
    return api.get('/address/validate', { params: { address } }).then(({ data }) => data)
  },

  enableAccessibility(enabled) {
    this.state.enableAccessibility = enabled
    Cookies.set('enable_accessibility', enabled)
  }
}
