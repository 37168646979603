import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.DEV ? '/vantool/' : '/'),
  routes: [
    {
      path: '/wms',
      component: () => import('./components/wms/WmsIndex.vue'),
      meta: {
        app: 'wms'
      },
      children: [
        {
          path: '',
          component: () => import('./components/wms/WmsHome.vue'),
          name: 'wms-home',
          meta: {
            title: 'WMS'
          }
        },
        {
          path: 'restock',
          component: () => import('./components/wms/WmsRestock.vue'),
          props: {
            restockCurrentVan: true
          },
          name: 'wms-restock-current-van',
          meta: {
            title: 'Restocking your van'
          }
        },
        {
          path: 'transfer',
          component: () => import('./components/wms/WmsRestock.vue'),
          props: {
            restockCurrentVan: false
          },
          name: 'wms-transfer',
          meta: {
            title: 'Transfer products'
          }
        },
        {
          path: 'request-restock',
          component: () => import('./components/wms/WmsRestockRequest.vue'),
          name: 'wms-request-restock',
          meta: {
            title: 'Request a restock'
          }
        },
        {
          path: 'search',
          component: () => import('./components/wms/WmsSearch.vue'),
          name: 'wms-search',
          meta: {
            title: 'Search stock levels'
          }
        }
      ]
    },
    {
      path: '/',
      component: () => import('./components/VanToolHome.vue'),
      name: 'board',
      meta: {
        app: 'vantool',
        title: 'Vantool Board'
      },
      children: [
        {
          path: '/workorder/:workOrderId',
          component: () => import('./components/VanToolHome.vue')
        }
      ]
    },
    {
      path: '/login',
      component: () => import('./components/Login.vue'),
      name: 'login',
      meta: {
        app: 'vantool',
        title: 'Vantool Login'
      }
    }
  ]
})

export default router
