import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const vantoolAnalyticsPlugins = []
vantoolAnalyticsPlugins.push(
  googleAnalytics({
    measurementIds: ['G-LM93D2R5XG'],
    debug: import.meta.env.DEV || import.meta.env.STAGING,
    gtagConfig: {
      anonymize_ip: true
    }
  })
)

const vantoolAnalytics = Analytics({
  app: 'Vantool',
  plugins: vantoolAnalyticsPlugins
})

export { vantoolAnalytics }
