import { useAccountStore } from '@/vantool/stores/account'
import api from '@/vantool/utils/axios'

export default {
  todayVan() {
    const params = {
      van: useAccountStore().account?.current_van?.id || null
    }

    return api.get('/bookings/today/', { params }).then(({ data }) => {
      this.state.bookings = data
      return data
    })
  },

  todayAll() {
    return api.get('/bookings/today/').then(({ data }) => {
      this.state.allBookings = data
      return data
    })
  },

  getForCurrentUser() {
    return api
      .get('bookings/customer/', { params: { id: this.state.account.id } })
      .then(({ data }) => {
        this.state.bookings = data
        return data
      })
  },

  deleteBookingById(id) {
    return api.delete(`/bookings/${id}/`)
  }
}
