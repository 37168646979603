import api from '@/vantool/utils/axios'
import createLogger from '@/vantool/utils/logger'

const logger = createLogger('Vans')

export default {
  async all() {
    let response
    try {
      response = await api.get('/vantool/vans')
    } catch (error) {
      error.message = `Failed fetching vans: ${error.message}`
      logger.error(error.message)
      throw error
    }

    this.state.vans = response.data
    return this.state.vans
  },

  getLocationDetails(locationId) {
    return api.get(`/locations/${locationId}/`).then(({ data }) => data)
  }
}
