import api from '@/vantool/utils/axios'

export default {
  current() {
    return api.get('/franchises/current').then(({ data }) => {
      if (this.state.franchises === undefined) {
        this.state.franchises = {
          current: data
        }
      } else {
        this.state.franchises.current = data
      }
      return this.state.franchises.current
    })
  }
}
