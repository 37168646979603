import { createApp, nextTick } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import '@fontsource/barlow/latin.css'
import '@fontsource/open-sans/latin.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowsRotate,
  faCircleCheck,
  faCircleXmark,
  faPlus,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Quasar, Notify, Dialog, AppFullscreen } from 'quasar'
import 'quasar/src/css/index.sass'
import 'quasar/src/css/flex-addon.sass'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css'

import App from './App.vue'
import router from './router'
// @ts-ignore
import createStore from './store'
// @ts-ignore
import { setupExternalScanningDevice } from './utils/qr-code'
import { vantoolAnalytics as analytics } from './utils/analytics'
import '@/scss/main.scss'

library.add(faArrowsRotate, faCircleCheck, faCircleXmark, faPlus, faXmark)

const app = createApp(App)

app.use(router)

router.afterEach((to, from) => {
  // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title.toString()
    }
    analytics.page()
  })
})

export const store = createStore()
store.router = router
app.config.globalProperties.$actions = store.actions
app.config.globalProperties.$state = store.state

// setupExternalScanningDevice(document, store.state)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = router
})

app.use(pinia)

app.use(Quasar, {
  plugins: {
    Notify,
    Dialog,
    AppFullscreen
  },
  config: {
    brand: {
      primary: '#feed46',
      dark: '#1d1d1b',
      'dark-page': '#1d1d1b'
    },
    dark: false,
    screen: {
      bodyClasses: true
    }
  }
})

app.component('FontAwesomeIcon', FontAwesomeIcon)

if (import.meta.env.PROD && !import.meta.env.STAGING) {
  Sentry.init({
    app,
    dsn: 'https://69b3225e02d54305a83f232c89979f05@o1243369.ingest.sentry.io/6398708ä',
    environment: 'production',
    trackComponents: true,
    logErrors: true,
    integrations: [
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      })
    ],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0
  })
}

app.mount('#app')
