import api from '@/vantool/utils/axios'

export default {
  getPublic(forceRefresh = false) {
    if (this.state.account && !forceRefresh) {
      return Promise.resolve(this.state.account)
    }
    return api
      .get('/public/v1/account/')
      .then(({ data }) => {
        this.state.account = data
        return this.state.account
      })
      .catch((error) => {
        this.state.account = null
        this.router.push({ name: 'login' })
      })
  }
}
