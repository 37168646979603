import api from '@/vantool/utils/axios'

export default {
  createIfNeeded(params) {
    if (params.id) {
      return Promise.resolve(params)
    } else {
      return api.post('/customerprofiles/', params).then(({ data }) => {
        console.log('CUSTOMERPROFILE created with success', data)
        return this.actions.customerprofiles.get().then(() => data)
      })
    }
  },

  async update(userId, params) {
    let customerProfile
    try {
      customerProfile = await api.patch(`/customerprofiles/${userId}/`, params)
    } catch (error) {
      error.message = `Failed updating customer profile of user ${userId}: ${error.message}`
      throw error
    }

    console.log('CUSTOMER PROFILE: updated with success', customerProfile)
    return customerProfile
  }
}
