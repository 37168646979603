import api from '@/vantool/utils/axios'

export default {
  getTypes() {
    return api.get('/workorders/tasktypes/').then(({ data }) => {
      console.log('WORKORDERTASKTYPES: tasks found with success', data)
      this.state.workordertasktypes = data
      return data
    })
  },

  async getAllTypes() {
    let response
    try {
      response = await api.get('/workorders/tasktypes/', { params: { show_hidden: 1 } })
    } catch (error) {
      error.message = `Failed fetching all workorder task types: ${error.message}`
      throw error
    }

    this.state.workordertasktypes = response.data
    return response.data
  }
}
