import api from '@/vantool/utils/axios'

export default {
  all() {
    return api.get('/vantool/products').then(({ data }) => {
      console.log('PRODUCTS: found', data)
      this.state.products = data
      return data
    })
  },

  async fetchCategories() {
    return api.get('vantool/product-categories').then((response) => {
      this.state.productCategories = response.data
      return this.state.productCategories
    })
  },

  // FIXME: unsafe use of same state variable for two different actions
  allPublic() {
    if (this.state.products.length > 0) {
      return Promise.resolve(this.state.products)
    }
    return api.get('/public/v1/products/').then(({ data }) => {
      console.log('PRODUCTS: found', data)
      this.state.products = data
      return data
    })
  }
}
