import api from '@/vantool/utils/axios'

export default {
  all() {
    return api.get('/feedback/questionnaires/').then(({ data }) => {
      this.state.questionnaires = data
      return data
    })
  },

  async answer(params) {
    let response
    try {
      response = await api.post('/feedback/answersets/', params)
    } catch (error) {
      error.message = `Failed creating answerset: ${error.message}`
      throw error
    }

    console.log('ANSWERSET: create with success', response.data)
    return response.data
  }
}
