import api from '@/vantool/utils/axios'

export default {
  getTypes() {
    if (this.state.workorderremarktypes.length > 0) {
      return Promise.resolve(this.state.workorderremarktypes)
    }
    return api.get('/workorders/remarktypes/').then(({ data }) => {
      console.log('WORKORDERREMARKTYPES: remarks found with success', data)
      this.state.workorderremarktypes = data
      return data
    })
  },

  getPublicTypes() {
    if (this.state.publicWorkorderremarktypes.length > 0) {
      return Promise.resolve(this.state.publicWorkorderremarktypes)
    }
    return api.get('/public/v1/workorders/remarktypes/').then(({ data }) => {
      console.log('WORKORDERREMARKTYPES: remarks found with success', data)
      this.state.publicWorkorderremarktypes = data
      return data
    })
  }
}
