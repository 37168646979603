import api from '@/vantool/utils/axios'

export default {
  async fetchByBikeID(bikeID) {
    try {
      const response = await api.get(`/inspections/?bike_id=${encodeURIComponent(bikeID)}`)
      return response.data
    } catch (error) {
      error.message = `Failed fetching inspections for bike ${bikeID}: ${error.message}`
      throw error
    }
  },

  update(list /* of { id, result, comments } */) {
    ;(Array.isArray(list) ? list : [list]).forEach((entry) =>
      api.patch(`/inspections/${entry.id}/`, {
        result: entry.result,
        comments: entry.comments
      })
    )
  }
}
