import api from '@/vantool/utils/axios'

export default {
  get() {
    return api
      .get('/statuses/')
      .then(({ data }) => {
        this.state.statuses = data
        return data
      })
      .catch((error) => {
        error.message = `Failed loading statuses: ${error.message}`
        throw error
      })
  }
}
